type HeadingSectionProps = {
  title: string
  titleColor: string
}

function HeadingSection({ title, titleColor }: HeadingSectionProps) {
  return (
    <h2
      className={`xl:text-[40px] lg:text-[40px] md:text-[40px] text-[30px] font-bold uppercase italic text-center ${titleColor} xl:mb-12 lg:mb-12 md:mb-12 mb-[26px] xl:mt-[62px] lg:mt-[62px] md:mt-[62px] mt-10`}
    >
      {title}
    </h2>
  )
}

export default HeadingSection
