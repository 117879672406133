export function serializeDateIsoFormat(data: string | any) {
  if (!data) {
    return ''
  }

  const array = data.split('-')
  const invertDaysToUSAFormat = array[0].trim().split('/').reverse().join('-')

  if (array[1].trim().length < 5) {
    array[1] = `0${array[1].trim()}`
  }

  return `${invertDaysToUSAFormat}T${array[1].trim()}-03:00`
}

export function getOffersAvaibility(
  time: Array<Record<string, string>> | any
): Array<Record<string, string>> | any {
  return time?.filter((item: any) => {
    const dateNow = new Date().getTime()
    const dateStart = new Date(
      serializeDateIsoFormat(item?.startDateOfOffer)
    ).getTime()

    const dateEnd = new Date(
      serializeDateIsoFormat(item?.endDateOfOffer)
    ).getTime()

    return dateStart < dateNow && dateEnd > dateNow
  })
}
