/* eslint-disable @typescript-eslint/no-explicit-any */
import HeadingSection from 'src/components/common/HeadingSection'
import { useStaticQuery, graphql } from 'gatsby'
import Link from 'src/components/ui/Link'
import Section from 'src/components/sections/Section'
import { useTheme } from 'src/components/contexts/ThemeContext'
import { useStoreConfig } from 'src/hooks/useStoreConfig'

import './styles.scss'
import type { GetBanners } from '../Banner/types'

function CategoriesMostWanted() {
  const data = useStaticQuery<GetBanners>(graphql`
    query GetTopSearched {
      allCmsHome {
        nodes {
          id
          sections {
            data
            id
            name
          }
        }
      }
    }
  `)

  // @todo
  const { tenant } = useStoreConfig()
  let nodeId = '1f3918dd-3f19-5639-a7b3-54d1049a46f9'

  if (tenant === 'B2C') {
    nodeId = 'bb392ef6-69eb-5343-b103-1f97fdfa1576'
  }

  const topSearched = data?.allCmsHome?.nodes.find((item) => item.id === nodeId)

  const topSearchedCheck = topSearched?.sections.find(
    (item) => item.id === '1652292948115'
  )

  const { theme, themes } = useTheme()

  const { mostWanted } = themes.filter((item) => item.key === theme)[0].styles

  return (
    <Section className="layout__content layout__section !mt-0">
      <HeadingSection title="mais procurados" titleColor={mostWanted.heading} />

      <div className="flex flex-wrap shrink items-center justify-center w-full max-w-[900px] m-auto gap-6">
        {topSearchedCheck?.data?.tags.map((item: any, index: number) => (
          <Link
            key={index}
            href={item.tagsUrl}
            target="_self"
            variant={theme}
            className={`${mostWanted.chipsBackground} ${topSearchedCheck?.name} !${mostWanted.chipsText} !no-underline !text-center w-max ppp:!text-[10px] pp:!text-xs md:!text-xl !py-2 !px-4 !rounded-full !uppercase mostWantedLinks`}
            data-banner-type={topSearchedCheck?.name}
          >
            {item.tagsDescription}
          </Link>
        ))}
      </div>
    </Section>
  )
}

export default CategoriesMostWanted
