import { memo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, Lazy } from 'swiper'

import ProductCard from '../product/ProductCardV2'
import type { CollectionBlueData } from '.'
import type { BlueFridayProductsProps } from './types'

type Props = {
  blueData: CollectionBlueData | any
  expiryFlag: boolean
}

const BlueFridayCarousel = ({ blueData, expiryFlag }: Props) => {
  return (
    <>
      {expiryFlag ? (
        <div className="expired-sale w-full bg-[#192023] rounded-lg flex flex-col py-[277px] gap-2">
          <h2 className="uppercase text-6xl text-[#EFF1F3] font-bold text-center w-min mx-auto">
            ofertas encerradas
          </h2>
          <p className="uppercase text-[#EFF1F3] font-bold text-sm text-center w-max mx-auto">
            em breve uma nova coleção promocional
          </p>
        </div>
      ) : (
        <Swiper
          loop
          navigation={{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
          }}
          pagination={{
            el: '.swiper-pagination',
            clickable: true,
            bulletActiveClass: `swiper-bullet-active-blueFriday`,
            renderBullet(_, className) {
              return `<span style="display: block; height: 11px; width: 11px; border: 1px solid #3D9BCC; border-radius: 3px" class="${className}"></span>`
            },
          }}
          lazy={{
            loadPrevNext: true,
          }}
          slidesPerView={2}
          modules={[Pagination, Navigation, Lazy]}
          className="swiper-shelf-blueFriday relative vs:w-full"
          breakpoints={{
            900: {
              slidesPerView: 3,
            },
          }}
        >
          <div className="swiper-button-prev !flex bg-neutral10 blueFridayArrows" />
          <div className="swiper-button-next !flex bg-neutral10 blueFridayArrows" />
          {blueData?.products?.map(
            (product: BlueFridayProductsProps, index: number) => {
              return (
                <SwiperSlide key={product.productId}>
                  <ProductCard
                    orientation="column"
                    key={product?.productId}
                    product={product as any}
                    index={index}
                  />
                </SwiperSlide>
              )
            }
          )}
          <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal flex justify-center !absolute !bottom-0" />
        </Swiper>
      )}
    </>
  )
}

export default memo(BlueFridayCarousel)
