/* eslint-disable @typescript-eslint/no-explicit-any */
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { useStaticQuery, graphql } from 'gatsby'
import Section from 'src/components/sections/Section'
import { Picture } from 'src/components/ui/Image'
import Link from 'src/components/ui/Link'
import { useStoreConfig } from 'src/hooks/useStoreConfig'

import type { GetBanners } from '../Banner/types'
import './main-banner-carousel.scss'
import '../../../utils/styles/splideArrows.scss'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'

function MainBannerCarousel() {
  const data = useStaticQuery<GetBanners>(graphql`
    query GetBannerCarousel {
      allCmsHome {
        nodes {
          id
          sections {
            data
            id
            name
          }
        }
      }
    }
  `)

  // @todo
  const { tenant } = useStoreConfig()
  let nodeId = '1f3918dd-3f19-5639-a7b3-54d1049a46f9'

  if (tenant === 'B2C') {
    nodeId = 'bb392ef6-69eb-5343-b103-1f97fdfa1576'
  }

  const carouselHome = data?.allCmsHome?.nodes.find(
    (item: any) => item.id === nodeId
  )

  const onlyCarouselsHome = carouselHome?.sections.find(
    ({ name }) => name === 'Carousel Home'
  )

  return (
    <Section className="main-banner-section layout__content px-2">
      <Splide
        options={{
          pagination: false,
          type: 'loop',
        }}
        hasTrack={false}
      >
        <SplideTrack>
          {onlyCarouselsHome?.data?.images.map((item: any, index: number) => {
            return (
              <SplideSlide
                key={index}
                data-index={`element-splid-item${index}`}
                aria-label={item.imageTitle}
              >
                <Link href={item.imageUrl}>
                  <Picture
                    data-banner-type={onlyCarouselsHome?.name}
                    sources={[
                      {
                        src: item.imageMobile,
                        width: 304,
                        height: 426,
                        media: '(max-width: 766px)',
                      },
                      {
                        src: item.imageDesktop,
                        width: 1304,
                        height: 636,
                        media: '(min-width: 767px)',
                      },
                    ]}
                    img={{
                      src: item.imageDesktop,
                      width: 1304,
                      height: 636,
                      alt: item.imageTitle,
                      loading: 'eager',
                    }}
                  />
                </Link>
              </SplideSlide>
            )
          })}
        </SplideTrack>
      </Splide>
    </Section>
  )
}

export default MainBannerCarousel
