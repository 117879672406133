/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'

interface Timer {
  countDownDays?: string
  countDownHours: string
  countDownMinutes: string
  countDownSeconds: string
}

interface DateToProps {
  dataExpires: string
  expiryFlag: boolean
  setExpiryFlag: (item: boolean) => void
  style?: Record<any, any>
}

export const CountDown = ({
  dataExpires,
  expiryFlag,
  setExpiryFlag,
  style = { color: '#000', backgroundColor: '#0043F8', colorTitle: '#000' },
}: DateToProps) => {
  const [expiryDate, setExpiryDate] = useState<DateToProps | any>(dataExpires)
  const [hide, setHide] = useState<boolean>(false)
  const [countDown, setCountDown] = useState<Timer>({
    countDownDays: '',
    countDownHours: '',
    countDownMinutes: '',
    countDownSeconds: '',
  })

  useEffect(() => {
    const getCountDown = () => {
      const intervalTime = setInterval(() => {
        const getDate = new Date(dataExpires)
        const currentTime = new Date()
        const remainingTime = getDate.getTime() - currentTime.getTime()

        const totalDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24))
        const totalHours = Math.floor(remainingTime / (60 * 60 * 1000))

        const totalMinutes = Math.floor(
          (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
        )

        const totalSeconds = Math.floor((remainingTime % (1000 * 60)) / 1000)

        const runningCountDown = {
          countDownDays: String(totalDays).padStart(2, '0'),
          countDownHours: String(totalHours).padStart(2, '0'),
          countDownMinutes: String(totalMinutes).padStart(2, '0'),
          countDownSeconds: String(totalSeconds).padStart(2, '0'),
        }

        setCountDown(runningCountDown)

        if (remainingTime > 0) {
          if (hide === true) {
            setHide(false)
          }

          setExpiryFlag(false)
        } else {
          clearInterval(intervalTime)
          setHide(true)
          setCountDown({
            countDownHours: '00',
            countDownMinutes: '00',
            countDownSeconds: '00',
          })
          setExpiryDate('')
          setExpiryFlag(true)
        }
      }, 1000)
    }

    getCountDown()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiryDate, setExpiryFlag, dataExpires])

  const numberClasses = `font-bold ${
    expiryFlag ? 'text-[#4E5D6B]' : 'text-[#001018]'
  } ppp:text-3xl xs:text-6xl flex items-center flex-col`

  const separatorClasses = `ppp:text-[30px] xs:text-[52px] leading-[52px] font-bold ${
    expiryFlag ? 'text-[#4E5D6B]' : 'text-[#001018]'
  } px-2`

  const timeClasses = 'ppp:text-sm xs:text-xl font-bold'

  return hide ? (
    <div />
  ) : (
    <>
      <section className="max-w-[550px] w-full flex-col flex gap-2 ppp:p-0 ppp:pt-2 xs:pt-4 xs:pr-4 xs:pb-4 font-roboto">
        <h4
          className={`font-bold ${
            expiryFlag ? 'text-[#344450]' : 'text-white'
          } m-0 uppercase ppp:text-xs ppp:text-center xs:text-lg xs:text-left`}
          style={{ color: style.colorTitle || '#000' }}
        >
          Os preços vão aumentar em:
        </h4>
        <div
          className={`flex ppp:rounded-lg xs:rounded-none justify-center align-center ${
            expiryFlag
              ? 'bg-[#B7BABE]'
              : 'bg-gradient-to-r from-[#0043F8] to-[#00ECFA]'
          } px-4 py-1`}
          style={{ background: style.backgroundColor }}
        >
          <p className={numberClasses} style={{ color: style.color }}>
            {countDown.countDownHours}
            <p className={timeClasses}>horas</p>
          </p>
          <span
            className={`${separatorClasses} separator-countDown`}
            style={{ color: style.color }}
          >
            :
          </span>
          <p className={numberClasses} style={{ color: style.color }}>
            {countDown.countDownMinutes}
            <p className={timeClasses}>min.</p>
          </p>
          <span
            className={`${separatorClasses} separator-countDown`}
            style={{ color: style.color }}
          >
            :
          </span>
          <p className={numberClasses} style={{ color: style.color }}>
            {countDown.countDownSeconds}
            <p className={timeClasses}>seg.</p>
          </p>
        </div>
      </section>
    </>
  )
}
