/* eslint-disable @typescript-eslint/no-explicit-any */
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Image from 'src/components/ui/Image/Image'
import { useMobile } from 'src/hooks/useMobile'
import { useTheme } from 'src/components/contexts/ThemeContext'
import { useStoreConfig } from 'src/hooks/useStoreConfig'

import Section from '../Section'
import type { GetBanners } from '../Banner/types'
import './slick-slide.scss'
import '../../../utils/styles/splideArrows.scss'
import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'

const SlickSlide = () => {
  const data = useStaticQuery<GetBanners>(graphql`
    query GetCategoriesHome {
      allCmsHome {
        nodes {
          id
          sections {
            data
            id
            name
          }
        }
      }
    }
  `)

  const { screenWidth } = useMobile()
  const { theme, themes } = useTheme()

  const { categories } = themes.filter((idx) => idx.key === theme)[0].styles

  // @todo
  const { tenant } = useStoreConfig()
  let nodeId = '1f3918dd-3f19-5639-a7b3-54d1049a46f9'

  if (tenant === 'B2C') {
    nodeId = 'bb392ef6-69eb-5343-b103-1f97fdfa1576'
  }

  const categoriesHome = data?.allCmsHome?.nodes.find(
    (item) => item.id === nodeId
  )

  const categoriesHomeCheck = categoriesHome?.sections.find(
    (item) => item.name === 'Categories Shelf'
  )

  if (!categoriesHomeCheck) {
    return null
  }

  return (
    <Section className="slick-slide-section pictos-sports layout__content layout__section px-5">
      <Splide
        options={{
          pagination: false,
          perPage: 10,
          gap: '25px',
          breakpoints: {
            1244: {
              perPage: 10,
            },
            1100: {
              perPage: 8,
              gap: '20px',
            },
            920: {
              perPage: 6,
            },
            768: {
              perPage: 4,
              gap: '15px',
            },
            460: {
              gap: '10px',
            },
            440: {
              perPage: 3,
              gap: '5px',
            },
            320: {
              gap: '2px',
            },
          },
        }}
        hasTrack={false}
      >
        <SplideTrack>
          {categoriesHomeCheck?.data?.images.map((item: any, index: number) => {
            return (
              <SplideSlide
                key={index}
                data-index={`element-splid-item${index}`}
                aria-label={item.descriptionTitle}
              >
                <Link
                  key={index}
                  to={item.imageCategoriesUrl}
                  className={`splid-slide-item ${categories.pictosStroke} ${categories.pictosTopBackground}`}
                >
                  <div className="overflow-hidden">
                    <Image
                      data-banner-type={categoriesHomeCheck?.name}
                      alt={`Acesso aos produtos da categoria: ${item.imageTitle}`}
                      src={item.imageCategories}
                      width={screenWidth < 375 ? 60 : 80}
                      height={screenWidth < 375 ? 60 : 80}
                      loading={index < 3 ? 'eager' : 'lazy'}
                      preload={index < 3}
                      sizes="(max-width: 766px) 60px, 100vw"
                      className={index >= 3 ? 'swiper-lazy' : ''}
                    />
                  </div>

                  <p
                    className={`${categories.pictosBottomText} ${categories.pictosBottomBackground}`}
                  >
                    {item.descriptionTitle}
                  </p>
                </Link>
              </SplideSlide>
            )
          })}
        </SplideTrack>
      </Splide>
    </Section>
  )
}

export default SlickSlide
