import { lazy, Suspense, useEffect } from 'react'
import { graphql } from 'gatsby'
import CategoriesMostWanted from 'src/components/sections/CategoriesMostWanted'
import SlickSlide from 'src/components/sections/SlickSlide'
import MainBannerCarousel from 'src/components/sections/MainBannerCarousel'
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo'
import { mark } from 'src/sdk/tests/mark'
import type { PageProps } from 'gatsby'
import type { HomePageQueryQuery } from '@generated/graphql'
import { useTheme } from 'src/components/contexts/ThemeContext'
import { Helmet } from 'react-helmet'
import { useLinxHomeView } from 'src/sdk/linx/hooks/useLinxHomeView'
import ShelfBlueFridayCMS from 'src/components/ShelfBlueFridayCMS'

const ShelfLinx = lazy(() => import('src/components/ShelfLinx/ShelfLinx'))

const BannerAccessories = lazy(
  () => import('src/components/sections/Banner/BannerAccessories')
)

const BannerApp = lazy(() => import('src/components/sections/Banner/BannerApp'))
const BannerOurSuggestions = lazy(
  () => import('src/components/sections/Banner/BannerOurSuggestions')
)

const BannerProDouble = lazy(
  () => import('src/components/sections/Banner/BannerProDouble')
)

const BannerOurBrands = lazy(
  () => import('src/components/sections/Banner/BannerOurBrands')
)

const BannerOurServices = lazy(
  () => import('src/components/sections/Banner/BannerOurServices')
)

const BannerRainCoat = lazy(
  () => import('src/components/sections/Banner/BannerRainCoat')
)

export type Props = PageProps<HomePageQueryQuery>

function Page(props: Props) {
  const {
    data: { site },
  } = props

  const { theme, themes } = useTheme()
  const { sendHomeViewEvent } = useLinxHomeView()

  const { homeBackground } = themes.filter((item) => item.key === theme)[0]
    .styles

  const title = site?.siteMetadata?.title ?? ''
  const siteUrl = site?.siteMetadata?.siteUrl ?? `https://www.decathlon.com.br`

  useEffect(() => {
    sendHomeViewEvent()
  }, [sendHomeViewEvent])

  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="fkisNN8vSDhgiyx5x02y1EYS9yV0x40h6rtdCSG0O7s"
        />
        <meta
          name="google-site-verification"
          content="HjvXBtZM-X_hXq-NaXZKuGnlRH_kGcVpavHD5QxRHaU"
        />
      </Helmet>
      <main className={`${homeBackground}`}>
        <GatsbySeo
          noindex
          nofollow
          title={title}
          description={site?.siteMetadata?.description ?? ''}
          titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
          language="pt-br"
          canonical={siteUrl}
          openGraph={{
            type: 'website',
            url: siteUrl,
            title: title ?? '',
            description: site?.siteMetadata?.description ?? '',
          }}
        />
        <JsonLd
          json={{
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            url: siteUrl,
            potentialAction: {
              '@type': 'SearchAction',
              target: `${siteUrl}/s/?q={search_term_string}`,
              'query-input': 'required name=search_term_string',
            },
          }}
        />

        <SlickSlide />

        <MainBannerCarousel />

        <h1 className="layout__content mt-2 text-neutral06 text-base text-center mb-0">
          Decathlon: a maior loja de artigos esportivos do mundo!
        </h1>

        <ShelfBlueFridayCMS />

        <Suspense fallback={null}>
          <CategoriesMostWanted />
        </Suspense>

        <Suspense fallback={null}>
          <ShelfLinx position="top" page="home" />
        </Suspense>

        <Suspense fallback={null}>
          <BannerAccessories />
        </Suspense>

        <Suspense fallback={null}>
          <ShelfLinx position="middle" page="home" />
        </Suspense>

        <Suspense fallback={null}>
          <BannerApp />
        </Suspense>

        <Suspense fallback={null}>
          <ShelfLinx position="area-bonus" page="home" />
        </Suspense>

        <Suspense fallback={null}>
          <BannerOurSuggestions />
        </Suspense>

        <Suspense fallback={null}>
          <BannerProDouble />
        </Suspense>

        <Suspense fallback={null}>
          <BannerOurBrands />
        </Suspense>

        <Suspense fallback={null}>
          <BannerRainCoat />
        </Suspense>

        <Suspense fallback={null}>
          <BannerOurServices />
        </Suspense>
        <Suspense fallback={null}>
          <ShelfLinx position="bottom" page="home" />
        </Suspense>
      </main>
    </>
  )
}

export const querySSG = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
        description
        titleTemplate
        siteUrl
      }
    }
  }
`

Page.displayName = 'Page'
export default mark(Page)
