import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { graphql, useStaticQuery } from 'gatsby'
import { CountDown } from 'src/components/product/CounterBlackFriday'
import {
  getOffersAvaibility,
  serializeDateIsoFormat,
} from 'src/utils/timeUtils'
import { useStoreConfig } from 'src/hooks/useStoreConfig'

import Section from '../sections/Section'
import ProductShelfSkeleton from '../skeletons/ProductShelfSkeleton'
import './shelf-blueFriday.scss'
import BlueFridayCarousel from './BlueFridayCarousel'

export type BlueDataProps = {
  id: string
  name: string
  carroselConfig: any
  enable: boolean
}

export type CollectionBlueData = {
  collectionId: string
  endDateOfOffer: string
  configTimer: {
    primaryColor: string
    secondaryColor?: string
    fontColorTime: string
  }
  startDateOfOffer: string
  timeIsActive: boolean
}

interface GetTimerOffers {
  allCmsHome: {
    nodes: Array<{
      id: string
      sections: Array<{ data: any; id: string | null; name: string }>
    }>
  }
}

type CarroselConfig = {
  title: string
  backgroundColor: string
  fontColor: string
}

interface TimeOfOffers {
  carrosel: any[]
  carroselConfig: CarroselConfig
  enable: boolean
}

function ShelfBlueFridayCMS() {
  const dataCMS = useStaticQuery<GetTimerOffers>(graphql`
    query GetTimerOffers {
      allCmsHome(
        filter: {
          sections: { elemMatch: { name: { eq: "Timer de ofertas" } } }
        }
      ) {
        nodes {
          id
          sections {
            name
            id
            data
          }
        }
      }
    }
  `)

  const [carroselInfos, setCarroselInfos] = useState<BlueDataProps>(
    {} as BlueDataProps
  )

  const [collection, setCollection] = useState<CollectionBlueData>(
    {} as CollectionBlueData
  )

  const [backgroundColor, setBackgroundColor] = useState<string>('')
  const [messageExpiryDate, setMessageExpiryDate] = useState<boolean>(false)

  const getBlueInfo = useCallback((collectionId: number) => {
    if (!collectionId) {
      return null
    }

    return axios.get(
      'https://decathlonstore.myvtex.com/_v2/api/decathlon-search/product/collection',
      {
        params: {
          engine: 'vtex',
          page: 1,
          resultsPerPage: 20,
          sc: 3,
          sort: 'relevance',
          term: collectionId,
        },
      }
    )
  }, [])

  // @todo
  const { tenant } = useStoreConfig()
  let nodeId = '1f3918dd-3f19-5639-a7b3-54d1049a46f9'

  if (tenant === 'B2C') {
    nodeId = 'bb392ef6-69eb-5343-b103-1f97fdfa1576'
  }

  const timerOffers = dataCMS?.allCmsHome?.nodes
    .find((item: any) => item.id === nodeId)
    ?.sections.find(({ id }) => id === '1677595546196')

  useEffect(() => {
    async function fetchData() {
      try {
        Array(timerOffers?.data).forEach(
          async ({ carrosel, carroselConfig, enable }: TimeOfOffers) => {
            let offersAvaibility = getOffersAvaibility(carrosel)

            if (!enable) {
              offersAvaibility = []
            } else if (offersAvaibility.length !== 1) {
              offersAvaibility = []
            } else if (!offersAvaibility[0]?.offerEnabled) {
              offersAvaibility = []
            }

            const obj = {
              id: timerOffers?.id,
              name: timerOffers?.name,
              carroselConfig,
            }

            const dataCollection = await getBlueInfo(
              offersAvaibility?.[0]?.collectionId
            )

            setCarroselInfos((prevState: BlueDataProps | any) => ({
              ...prevState,
              ...obj,
              name: timerOffers?.name,
            }))

            setCollection((prevState: CollectionBlueData) => ({
              ...prevState,
              ...dataCollection?.data,
              ...offersAvaibility[0],
              startDateOfOffer: serializeDateIsoFormat(
                offersAvaibility[0]?.startDateOfOffer
              ),
              endDateOfOffer: serializeDateIsoFormat(
                offersAvaibility[0]?.endDateOfOffer
              ),
            }))
            const timeBackground = offersAvaibility?.[0]?.configTimer

            setBackgroundColor(
              timeBackground?.secondaryColor
                ? `linear-gradient(to right, ${timeBackground?.primaryColor}, ${timeBackground?.secondaryColor}))`
                : timeBackground?.primaryColor
            )
          }
        )
      } catch (error) {
        console.error('error api | catalog/collection', error)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !collection?.collectionId ? (
    <div />
  ) : (
    <ProductShelfSkeleton loading={!collection}>
      <Section
        className={`layout__content layout__section shelf-blueFriday-section font-roboto w-full !max-w-[1440px] ${
          messageExpiryDate ? 'bg-[#808489]' : 'bg-[#1A2A34]'
        } flex ppp:gap-2 justify-center pb-[34px] pt-[34px] xs:gap-0 xs:flex-row vs:items-center flex-col`}
        style={{
          backgroundColor: carroselInfos?.carroselConfig?.backgroundColor,
        }}
      >
        <div className="title-shelf-blue flex-wrap flex ppp:gap-0 xl:items-start items-center flex-row pr-[71px] w-full restructure-tablet:flex-col">
          <p
            className={`w-full exclusive uppercase ppp:text-[34px] ppp:leading-[38px] xs:text-[72px] xs:leading-[74px] xs:text-left ${
              messageExpiryDate ? 'text-[#344450]' : 'text-white'
            } italic font-bold pt-1`}
            style={{ color: carroselInfos?.carroselConfig?.fontColor }}
          >
            {carroselInfos?.carroselConfig?.title}
          </p>
          <div data-banner-type={carroselInfos?.name} className="w-full mb-2">
            <CountDown
              dataExpires={collection?.endDateOfOffer}
              expiryFlag={messageExpiryDate}
              setExpiryFlag={setMessageExpiryDate}
              style={{
                color: collection?.configTimer?.fontColorTime,
                backgroundColor,
                colorTitle: carroselInfos?.carroselConfig?.fontColor,
              }}
            />
          </div>
        </div>
        <BlueFridayCarousel
          blueData={collection}
          expiryFlag={messageExpiryDate}
        />
      </Section>
    </ProductShelfSkeleton>
  )
}

export default ShelfBlueFridayCMS
